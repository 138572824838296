import React from "react";
import { Grid, Typography, Button, FormHelperText, IconButton } from "@mui/material";
import InsertFileIcon from "../../../assets/images/InsertFileIcon.png";
import { makeStyles } from "@mui/styles";
import DeleteIcon from '@mui/icons-material/Delete';
// import { Scrollbars } from 'react-custom-scrollbars-2';
import AddIcon from "@mui/icons-material/Add";


const useStyles = makeStyles(() => ({
  divContainer: {
    border: "3px dotted #634699",
    borderRadius: "4px",
    height: "auto",
    padding: "25px",
  },
  fileList: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    paddingBottom: "10px",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px", 
    padding: "10px",
    backgroundColor: "#fff", 
    borderRadius: "4px", 
    boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)", 
  },
  fileName: {
    color: "#634699",
  },
  deleteButton: {
    color: "#634699",
    backgroundColor: "#fff",
    borderRadius: "50%",
    padding: "5px",
  },
  plusButton: {
    color: "#fff",
    backgroundColor: "#634699",
    borderRadius: "50%",
    padding: "5px",
    width: "40px", 
    height: "40px", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#634699",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    },
  },
}));

const FileUploadArea = ({ draggedFiles, setDraggedFiles, setSnackbar,onFilesChange   }) => {
  const classes = useStyles();


  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const allowedFileTypes = ["image/png", "image/jpeg", "image/heic"];

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files).slice(0, 1);
   
   

    const oversizedFile = files.find((file) => file.size > 8 * 1024 * 1024);
    const invalidFile = files.find((file) => {
    
      const isHeicFile = file.name.toLowerCase().endsWith(".heic");  
      return !allowedFileTypes.includes(file.type) && !isHeicFile;
    });

    if (oversizedFile) {
      setSnackbar({
        open: true,
        message: `${oversizedFile.name}: File size limit exceeds!`,
      });
    } else if (invalidFile) {
      setSnackbar({
        open: true,
        message: `${invalidFile.name}: Unsupported file type! Only PNG, JPG, and HEIC are allowed.`,
      });
    } else {
      // setDraggedFiles((prevFiles) => {
      //   const updatedFiles = [...prevFiles, ...files];
      //   onFilesChange(updatedFiles); 
      //   return updatedFiles;   
      // });
      setDraggedFiles(files); // Directly set the single file
      onFilesChange(files);
    }
  };



  const handleBrowseFiles = (e) => {
    const files = Array.from(e.target.files).slice(0, 1);
    // const files = Array.from(e.target.files);
    const oversizedFile = files.find((file) => file.size > 8 * 1024 * 1024);
    const invalidFile = files.find((file) => {
      const isHeicFile = file.name.toLowerCase().endsWith(".heic");  
      return !allowedFileTypes.includes(file.type) && !isHeicFile;
    });
    if (oversizedFile) {
      setSnackbar({
        open: true,
        message: `${oversizedFile.name}: File size limit exceeds!`,
      });
    } else if (invalidFile) {
      setSnackbar({
        open: true,
        message: `${invalidFile.name}: Unsupported file type! Only PNG, JPG, and HEIC are allowed.`,
      });
    } else {
      setDraggedFiles(files); // Directly set the single file
      onFilesChange(files); 
      // setDraggedFiles((prevFiles) => {
      //   const updatedFiles = [...prevFiles, ...files];
      //   onFilesChange(updatedFiles); 
      //   return updatedFiles;
      // });
    }
  };

  // const handleRemoveFile = (fileToRemove) => {
  //   setDraggedFiles((prevFiles) => {
  //     const updatedFiles = prevFiles.filter((file) => file !== fileToRemove);
  //     onFilesChange(updatedFiles);
  //     return updatedFiles;
  //   });
  // };
  const handleRemoveFile = () => {
    setDraggedFiles([]);
    onFilesChange([]);
  };
  return (
    <div onDragOver={handleDragOver} onDrop={handleDrop}>
      <Grid container item xs={12} className={classes.divContainer}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 700,
              color: "#634699",
              fontSize: "1.5vw",
              textAlign: "center",
            }}
          >
            <img
              src={InsertFileIcon}
              alt="preview"
              style={{ width: "30px", height: "30px" }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 400,
              color: "#634699",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            Drag and Drop or Browse to Choose a file
          </Typography>
        </Grid>
      </Grid>

      <input
        type="file"
        id="select-image"
        style={{ display: "none" }}
        multiple
        onChange={handleBrowseFiles}
      />

      {draggedFiles.length === 0 ? (
        <>
          <label htmlFor="select-image">
            <Button
              variant="contained"
              color="primary"
              component="div"
              sx={{
                padding: "5px 30px",
                textTransform: "none",
                backgroundColor: "#634699",
                boxShadow: "none",
                marginTop: "10px",
                "&:hover": {
                  backgroundColor: "#634699",
                  boxShadow: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontStyle: "regular",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  lineHeight: "150%",
                }}
              >
                Browse
              </Typography>
            </Button>
          </label>
          <FormHelperText error sx={{ paddingLeft: "20px" }}>
            (Max. 8MB)
          </FormHelperText>
        </>
      ) : (
        <>
          {/* Scrollable area with files */}
          {/* <Scrollbars
            style={{
              height: 150,
              marginBottom: "10px",
              marginTop: "10px",
            }}
            renderThumbVertical={(props) => (
              <div
                {...props}
                style={{
                  backgroundColor: '#634699',
                  width: '6px',
                  borderRadius: '2px',
                  marginTop: "10px",
                }}
              />
            )}
          >
            <div className={classes.fileList}>
              {draggedFiles.map((file, index) => (
                <div
                  key={index}
                  className={classes.fileItem}
                >
                  <Typography className={classes.fileName}>
                    {file.name}
                  </Typography>
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => handleRemoveFile(file)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          </Scrollbars> */}

          <div style={{marginTop:"10px"}} className={classes.fileItem}>
            <Typography className={classes.fileName}>
              {draggedFiles[0].name}
            </Typography>
            <IconButton
              className={classes.deleteButton}
              onClick={handleRemoveFile}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            <IconButton
              onClick={() => document.getElementById("select-image").click()}
              className={classes.plusButton}
            >
             <AddIcon />
            </IconButton>
          </div> */}
        </>
      )}
    </div>
  );
};

export default FileUploadArea;
