import { MoodleURL } from "../config";
import { POST_METHOD } from "./ApiMethods";
import { decrypt } from "../../../encrypt";

export const bugReport = async (userid, title, description, isfileuploaded) => {
  try {
    let user = window.localStorage.getItem("userToken");
    let d = decrypt(user);

    const moodleUrl = MoodleURL(d);

    const isFileUploaded = isfileuploaded && isfileuploaded.length > 0 ? 1 : 0;


    const encodedTitle = encodeURIComponent(title);
    const encodedDescription = encodeURIComponent(description);

   
    const formData = new FormData();

    // if (isfileuploaded && isfileuploaded.length > 0) {
    //   isfileuploaded.forEach((file, index) => {
    //     formData.append("file[]", file);  // Append files with the same key "file[]"
    //     console.log(`File ${index + 1} appended:`, file);
    //   });
    // }

    if (isfileuploaded && isfileuploaded.length > 0) {
      formData.append("file", isfileuploaded[0]);  
    }

  
    const apiUrl = `${moodleUrl}&wsfunction=local_centapi_raisebug&userid=${userid}&heading=${encodedTitle}&description=${encodedDescription}&isfileuploaded=${isFileUploaded}`;

   
    const response = await POST_METHOD(apiUrl, formData, {
      method: "POST",
      headers: {
      },
    });

    if (!response || response.status !== 200) {
      throw new Error('Failed to report the bug. Try again later.');
    }

    return response;
  } catch (error) {
    throw error;
  }
};
