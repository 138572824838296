import React, { useState, useEffect } from "react";
import {Modal,Box, Typography, Button, Dialog, TextField,Alert } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import FileUploadArea from "./FileUploadArea";
import { reportBugAction } from "../../../webservice/students/actions/BugReportAction";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import Fileuploadvalidatescreen from "../../../../src/views/students/test/packages/Fileuploadvalidatescreen";

const BugReport = () => {
  const [open, setOpen] = useState(false);
  const [draggedFiles, setDraggedFiles] = useState([]);
  const { userid } = useSelector((state) => state.authState);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [files, setFiles] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");  
  const [alertType, setAlertType] = useState("success");  

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setTitle("");
    setDescription("");
    setTitleError("");
    setDescriptionError("");
    setDraggedFiles([]);
    setFiles([]);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchUserId());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title) {
      setTitleError("Title is required.");
    }
    if (!description) {
      setDescriptionError("Description is required.");
    }
    if (!title || !description) {
      setSnackbar({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }

    try {
      const response = await dispatch(reportBugAction(userid, title, description, files));
      if (response.data.status === "true") {
        setAlertMessage("Issue reported successfully!"); 
        setAlertType("success");  
        
       
        setTimeout(() => {
          setAlertMessage("");  
          setAlertType("success");  
        }, 3000);
        handleClose();
      }
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to report the issue. Try again later." });
    }
  };

  useEffect(() => {
    if (snackbar.open) {
      const timer = setTimeout(() => setSnackbar({ ...snackbar, open: false }), 5000);
      return () => clearTimeout(timer);
    }
  }, [snackbar.open]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "650px",
    maxWidth: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "auto",
    zIndex: 1300,
  };

  return (
    <div style={{ padding: "10px" }}>
      <Button
        variant="outlined"
        className="flag-button"
        sx={{
          color: "#fff",
          backgroundColor: "#634699",
          "&:hover": { backgroundColor: "#634699" },
          borderRadius: "5px",
          padding: "5px",
          fontWeight: 600,
          margin: "10px auto",
          display: "block",
          
          textTransform: "none",
        }}
        onClick={handleOpen}
      >
        Flag a Problem
      </Button>

      <Modal
        open={open}
        onClose={(e, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          handleClose();
        }}
      >
        <Box sx={style}>
          <Typography
            variant="body2"
            sx={{
              fontSize: 20,
              color: "#634699",
              fontWeight: 700,
            }}
          >
            Please add a clear title and description for the issue you've identified.
          </Typography>
          <form style={{ marginTop: "10px" }} onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", mb: 1, color: "#634699" }}
              >
                Title
              </Typography>
              <TextField
                type="text"
                placeholder="Technical Glitches"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleError("");
                }}
                variant="outlined"
                fullWidth
                error={!!titleError}
                helperText={titleError}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    "& fieldset": {
                      borderColor: "#634699",
                      borderWidth: "2px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#5e2b97",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3b1a67",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", mb: 1, color: "#634699" }}
              >
                Description
              </Typography>
              <TextField
                multiline
                rows={4}
                placeholder="Link in a lesson doesn't work or unable to access a video lecture due to buffering issues."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setDescriptionError("");
                }}
                variant="outlined"
                fullWidth
                error={!!descriptionError}
                helperText={descriptionError}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    "& fieldset": {
                      borderColor: "#634699",
                      borderWidth: "2px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#5e2b97",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#3b1a67",
                    },
                  },
                }}
              />
            </Box>

            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", color: "#634699" }}
            >
              Attachments
            </Typography>
            <Typography variant="body2" sx={{ color: "#634699" }}>
              Take a screenshot of the issue
            </Typography>

            <FileUploadArea
              draggedFiles={draggedFiles}
              setDraggedFiles={(files) => {
                setDraggedFiles(files);
                setFiles(files);
              }}
              onFilesChange={(updatedFiles) => {
              }}
              setSnackbar={setSnackbar}
            />

            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Button
                variant="outlined"
                sx={{
                  color: "#634699",
                  borderColor: "#634699",
                  textTransform: "none",
                  fontWeight: "bold",
                  marginRight: "10px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#634699",
                  "&:hover": { backgroundColor: "#634699" },
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <Dialog
        PaperProps={{
          style: {
            maxHeight: "none",
          },
        }}
        open={snackbar.open}
        sx={{
          backgroundColor: "rgba(99, 70, 153,  60%)",
        }}
      >
        <Fileuploadvalidatescreen
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          message={snackbar.message}
        />
      </Dialog>
    

      {alertMessage && (
          <Alert
        severity={alertType}  
        sx={{
          position: "fixed",
          top: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "auto",
          zIndex: 1300,
          padding: "10px 20px",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        {alertMessage} 
      </Alert>
    )}
    </div>
  );
};

export default BugReport;
