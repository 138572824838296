 import { bugReport } from "../api/BugReportApi";

export const reportBugAction = (userid, title, description,isfileuploaded) => async (dispatch) => {
  try {
    const response = await bugReport(userid, title, description,isfileuploaded);
    dispatch({ type: "BUG_REPORT_SUCCESS", payload: response });
    return response;
  } catch (error) {
    dispatch({ type: "BUG_REPORT_FAILURE", payload: error });
    throw error;
  }
};
